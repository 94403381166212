.formGroup {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 0.1rem;
  border-bottom: 0.1rem solid #ccc;
  border-top: 0.1rem solid #ccc;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.label {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: var(--margin-right-labelbox); /*14rem*/
  color: var(--color-text);
  font-size: var(--font-size);
  font-family: Omu;
}

.input {
  width: 8rem;
  padding: 0.3rem;
  /* border: 0.1rem solid #ccc; */
  border-radius: 1rem;
  margin-left: 0.7rem;
  font-size: var(--font-size);
}
