.profile {
  justify-content: center;
  align-items: center;
  width: 70%;
  text-align: center;
  padding: 1rem;
  /* 세로방향 | 가로방향 */
  margin: 1% auto;
  background-color: #ebebeb;
  border-radius: 20px;
  font-size: var(--chatbot-font-size);
  /* margin-bottom: 1rem; */
  margin: 0rem auto 1rem;
  box-shadow: 8px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 8px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 8px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.photo {
  width: 30%;
  height: 80%;
  border-radius: 100%;
  left: 50%;
}

.loadingSpinner {
  width: 2rem;
  height: 2rem;
  border: 0.3rem solid #f3f3f3;
  border-top: 0.4rem solid #4caf50;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.paragraph {
  margin-bottom: 1rem;
}
