:root {
  --color-bg-dark: #f5f5f5;
  --color-bg: #fdfffd;
  --color-grey: #d1d1d1;
  --color-text: #ffffff;
  --color-accent: #f16e03;
  --color-white: white;
  --font-size: 2rem;
  --chatbot-font-size: 1.3rem;
  --font-size-up: 4rem;
  --margin-right-labelbox: 40%;
  --font-size-mobile: 1.1rem;
  --font-size-up-mobile: 1.1rem;
  --title-font-size: 10rem;
  --description-font-size: 2rem;
  --share-link-height: 3rem;
  --share-link-width: 3rem;
}
@font-face {
  font-family: "Omu";
  font-weight: 50;
  src: url("./fonts/omu.ttf") format("truetype");
}
@media (max-width: 480px) {
  :root {
    --font-size: var(--font-size-mobile);
    --font-size-up: var(--font-size-up-mobile);
    --chatbot-font-size: var(--font-size-mobile);
    --margin-right-labelbox:: 40%;
    --title-font-size: 2.2rem;
    --description-font-size: 0.9rem;
    --share-link-height: 1rem;
    --share-link-width: 1rem;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
