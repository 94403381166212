@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

.chatbot-container {
  width: 400px;
  height: 600px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(135deg, #6a82fb, #fc5c7d);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  font-family: "Roboto", sans-serif;
}

.messages-container {
  height: 500px;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  margin-bottom: 10px;
  max-width: 70%;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.bot-message {
  background-color: #f1f1f1;
  align-self: flex-start;
}

.user-message {
  background-color: #4caf50;
  color: white;
  align-self: flex-end;
}

.input-form {
  display: flex;
  padding: 10px;
}

.input-form input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.input-form button {
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.input-form input:focus,
.input-form button:focus {
  outline: none;
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #4caf50;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.avenue-messenger {
  position: relative;
}
.avenue-messenger::before {
  content: "";
  background-image: url("/public/data/bg.png");
  width: 100vw;
  height: 300vh;
  background-size: cover;

  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute; */
  position: absolute;
  top: 0vh;
  left: 0vw;
  right: 0px;
  bottom: 0px;
  z-index: -1;

  opacity: 0.7;
}
