.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 87%;
  height: 100%;
  /* 세로방향 | 가로방향 */
  margin: 5% auto;

  background-color: #000000;
  border-radius: 20px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Omu;
  color: var(--color-accent);
  font-size: var(--title-font-size);
  padding: 0%;
  padding-bottom: 0.1rem;
}

.advertisement_shared {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  border-radius: 1rem;
  height: 50%;
  width: 50%;
  justify-content: center;
  align-content: center;
  margin-left: 25%;
  margin-top: -10%;
}
/* style={{
  borderRadius: "1rem",
  height: "50%",
  width: "50%",
  alignContent: "center",
  justifyContent: "center",
  marginLeft: "25%",
}} */
